import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ScoresPage = () => (
  <Layout>
    <SEO title="Scores" />
    <h1>Scores</h1>
    <p>Under Constructin</p>
  </Layout>
)

export default ScoresPage
